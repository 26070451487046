<template>
  <div>
    <div class="section-header p-4">
      <h3 class="pb-2 section-title">{{$t('AffiliatedWith')}}</h3>
    </div>
    <div class="fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
        <div class="container-fluid">
          <div class="row gx-0 justify-content-between mx-4 mb-4 px-4">
            <div v-for="item in brandListing" :key="item.brandName" class="col-xl-1 col-lg-4 col-md-3">
              <div class="brand-item">
                <img :src="require(`@/assets/brands/${item.image}`)" :alt="item.brandName">
                </div>
             </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AffiliateList",
  computed: {
    brandListing() {
      return [
        {
          image:'ibm.png',
          brandName:"IBM"
        },
        {
          image:'confitarma.jpg',
          brandName:"Confitarma"
        },
        {
          image:'microsoft.png',
          brandName:"Microsoft"
        },
        {
          image:'aws.png',
          brandName:"AWS"
        }
      ]
    }
  }
}
</script>

<style scoped>
  .brand-item {
    margin: -1px 0 0 -1px;
    text-align: center;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s ease;
  }
</style>